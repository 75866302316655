/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@font-face {
  	font-family: 'geomanistregular';
    src: url('assets/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.eot');
    src: url('assets/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff2') format('woff2'),
         url('assets/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff') format('woff'),
         url('assets/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.ttf') format('truetype'),
         url('assets/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.svg#geomanistregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  	font-family: 'geomanistbold';
    src: url('assets/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.eot');
    src: url('assets/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff2') format('woff2'),
         url('assets/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff') format('woff'),
         url('assets/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.ttf') format('truetype'),
         url('assets/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.svg#geomanistregular') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
  font-family: 'geomanistbook';
    src: url('assets/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.eot');
    src: url('assets/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.woff2') format('woff2'),
         url('assets/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.woff') format('woff'),
         url('assets/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.ttf') format('truetype'),
         url('assets/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.svg#geomanistregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  	font-family: 'geomanistmedium';
    src: url('assets/fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.eot');
    src: url('assets/fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.woff2') format('woff2'),
         url('assets/fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.woff') format('woff'),
         url('assets/fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.ttf') format('truetype'),
         url('assets/fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.svg#geomanistregular') format('svg');
    font-weight: 500;
    font-style: normal;
}


/* Colors */
$yellow_1000: #8D2B0B;
$yellow_900: #B44D12;
$yellow_800: #CB6E17;
$yellow_700: #DE911D;
$yellow_600: #F0B429;
$yellow_500: #F7C948;
$yellow_400: #FADB5F;
$yellow_300: #FCE588;
$yellow_200: #FFF3C4;
$yellow_100: #FFFBEA;
$neutral_1000: #1F2933;
$neutral_900: #323F4B;
$neutral_800: #3E4C59;
$neutral_700: #52606D;
$neutral_600: #616E7C;
$neutral_500: #7B8794;
$neutral_400: #9AA5B1;
$neutral_300: #CBD2D9;
$neutral_200: #E4E7EB;
$neutral_100: #F5F7FA;
$white: #FFFFFF;
$red_1000: #610404;
$red_900: #780A0A;
$red_800: #911111;
$red_700: #A61B1B;
$red_600: #BA2525;
$red_500: #D64545;
$red_400: #E66A6A;
$red_300: #F29B9B;
$red_200: #FACDCD;
$red_100: #FFEEEE;
$pink: #E8178A;
$pink_24: rgba(232, 23, 138, 0.24);
$bg: #F0F0F0;
$green: #7BB026;

body {
	background-color: $neutral_100;
	font-family: 'geomanistregular';
	color: $neutral_1000;
}

.main-wrapper {
	height: 100%;
}

.splash-screen {
	position: absolute;
    top: 35%;
    /* adjust top up half the height of a single line */
    margin-top: -0.5em;
    /* force content to always be a single line */
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
}

.order-summary {
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: 0px 16px 16px -16px rgba(31, 41, 51, 0.2);
	padding: 32px 17px 16px;
	background-color: $neutral_100;
	z-index: 10;
	width: 100%;
	&.fake {
		position: relative;
		opacity: 0;
		height: 100%;
	}
	.order-label {
		font-size: 14px;
		line-height: 140%;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $neutral_600;
		padding-bottom: 4px;
	}
	.order-number {
		font-family: 'geomanistbold';
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;
		letter-spacing: 0.02em;
		color: $neutral_1000;
		padding-bottom: 4px;
	}
}

.image {
	max-width: 100%;
}

.divider {
	height: 1px;
	background: $neutral_200;
	width: 100%;
	margin: 12px 0;
}

.whole-order-time {
	font-size: 14px;
	line-height: 120%;
	text-align: right;
	color: $neutral_400;
	padding-top: 12px;
	padding-bottom: 12px;
} 

.order-status {
	display: flex;
  	align-items: center;
  	justify-content: center;
}
.status-update {
	padding-right: 9px;
	.status-update-indicator {
		width: 14px;
		height: 14px;
		padding: 3px;
		display: block;
		background-color: $neutral_200;
		border-radius: 50%;
		&.active {
			width: 17px;
			height: 17px;
			padding: 0;
			background-color: #7BB026;
			border: 3px solid #C7EA8F;
		}
	}
}
.status-text {
	font-size: 14px;
	line-height: 140%;
	color: $neutral_500;
}

.restaurant-order-item {
	width: 100%;
	margin: 10px 0;
	.restaurant-order-wrapper {
		background: #FFFFFF;
		border-radius: 8px;
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;
		padding: 16px 16px 8px;
		.restaurant-name {
			font-size: 18px;
			line-height: 22px;
			padding: 13px 0;
			display: flex;
			align-items: center;
			letter-spacing: 0.02em;
			font-family: 'geomanistmedium';
		}
		.restaurant-logo {
			width: 48px;
			height: 48px;
			/*box-shadow: inset 0px 0px 0px 1px rgba(31, 41, 51, 0.05);*/
			float: right;
			border-radius: 50%;
		}
	}
}

.order-status-wrapper {
	padding: 16px 0;
	.order-status-title {
		font-size: 14px;
		line-height: 140%;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $neutral_800;
		font-family: 'geomanistmedium';
	}
	.order-status-bar {
		margin: 8px -3px 4px;
	    height: 8px;
	    float: left;
	    width: calc(100% + 6px);
		.order-status-bar-part {
			width: 56px;
			height: 8px;
			background: $neutral_200;
			border-radius: 8px;
			float: left;
			border: 3px solid #fff;
	    	box-sizing: content-box;
			&.status-segment-first {
				margin-right: 1px;
			}
			&.status-segment-center {
				width: calc(100% - 132px);
			}
			&.status-segment-last {
				margin-left: 1px;
			}
			&.in-progress {
				background: $yellow_200;
				animation-delay: 0.3s;
				animation-duration: 2.6s;
				animation-name: animateProgressBar;
				animation-iteration-count: infinite;
			}
			&.completed {
				background: $yellow_400;
			}
		}
	}
	.order-status-text {
		font-size: 14px;
		line-height: 140%;
		color: $neutral_500;
		.order-status-time-value {
			font-family: 'geomanistmedium';
		}
	}
}

@keyframes animateProgressBar {
  	from {
    	background-color: $yellow_200;
    	border-color: #fff;
  	}

  	38% {
  		background-color: $yellow_300;
  		border-color: $yellow_200;
  	}

  	50% {
  		background-color: $yellow_300;
  		border-color: $yellow_200;
  	}

  	88% {
  		background-color: $yellow_200;
    	border-color: #fff;
  	}

  	to {
  		background-color: $yellow_200;
    	border-color: #fff;
  	}
}
